.co.payment-instructions{
	padding-top: 10px;
	.amount{
		background-color: #f0f0f0;
		padding: 15px;
		margin: auto;
		width: 250px;
		border-radius: 8px;
		text-align: center;

		.value{
			font-size: 32px;
			line-height: 36px;
			font-weight: bold;
		}
		.label{
			font-size: 16px;
			line-height: 20px;
			color: gray;
		}
		.meta{
			font-size: 12px;
			color: black;
		}
	}

	.fr.print.button{
		display: block;
		width: 200px;
		margin: auto;
		margin-bottom: 10px;

		@media print{
			display: none;
		}
	}

	.oxxo{
		img.barcode{
			width: 200px;
			display: block;
			margin: auto;
			margin-top: 15px;
		}
		.code{
			padding: 10px 15px;
			margin: auto;
			width: 200px;
			text-align: center;
			margin-top: 10px;
			background-color: #f0f0f0;
			border-radius: 8px;
			font-size: 18px;
			font-weight: bold;
		}
		ul{
			margin: auto;
			margin-top: 15px;
			max-width: 400px;
		}
	}

	.spei{
		ol{
			padding-left: 20px;
		}
		table{
			width: 100%;
			max-width: 400px;
			margin: auto;
			tr{
				td:first-child{
					font-weight: bold;
				}
				td:last-child{
					text-align: right;
				}
			}
		}
	}

	@media print{
		.fr.groupper{
			box-shadow: none;
			border: 1px solid #e5e7eb;
		}
	}

	table.fr.table{
		max-width: 400px;
		margin: auto;
		margin-top: 15px;
	}
}