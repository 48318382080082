@import '_colors.scss';

.co.terms-screen{
	padding: 15px;
	
	.main-container{
		max-width: 800px;
		margin: auto;
		text-align: left !important;
	}

	.main-header{
		text-align: left !important;
		font-size: 30px;
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.button-container{
		text-align: center;
		padding: 20px;
	}
}