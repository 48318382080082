@import '_colors.scss';

.co.help-screen{
	padding: 15px;
	
	.page-header{
		font-size: 35px;
	}
	
	.main-container{
		max-width: 800px;
		margin: auto;
		text-align: left !important;
	}

	.main-header{
		text-align: left !important;
		font-size: 30px;
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.secondary-header{
		text-align: left !important;
		font-size: 20px;
		font-weight: 510;
	}

	.mail-header{
		text-align: center;
		font-size: 20px;
		text-decoration: underline;
	}
}