@import "_colors.scss";

.co.checkout-screen{
	display: flex;
	margin-top: -10px;
	height: 100%;

	> *{
		height: calc(100vh - 50px);
	}
	
	> .contents{
		flex-grow: 100;
		background-color: white;
		padding: 15px;
		overflow-y: scroll;

		> .container{
			max-width: 600px;
			margin: auto;
		}

		.fr.breadcrumbs{
			margin-top: 5px;
			margin-bottom: 18px;
		}

		.fr.input:not(.normal){
			input{
				font-size: 16px;
				padding: 12px;
			}
		}

		.fr.small.header{
			margin-top: 30px;
			text-align: left;
			font-size: 18px;
		}

		.fr.items{
			.item{
				font-size: 16px;
				border-radius: 8px;
				background-color: #f5f5f5;
				margin-bottom: 5px;
				flex-direction: column;
				padding: 0;
				position: relative;
				align-items: normal;
				cursor: default;

				.header{
					cursor: pointer;
					width: 100%;
					padding: 15px;
					flex-grow: 100;
					display: flex;
					align-items: center;

					.left{
						flex-grow: 100;
						.meta{
							font-size: 14px;
							line-height: 14px;
						}
					}
					.cost{
						color: brown;
						&.free{
							color: gray;
						}
					}

					&.disabled{
						color: #BBB;
						.cost{
							color: #BBB;
						}
						.left .meta{
							color: #c5a2a2;
						}
					}
				}
				.content{
					margin: 0 10px 15px 10px;
					border-radius: 6px;
					padding: 15px;
					// width: 100%;
					background-color: #f9f9f9;
				}
				i{
					font-size: 20px;
					line-height: 20px;
					margin-right: 10px;
				}

				&.active .content .fr.input input{
					background-color: white;
				}
				&:has(.header.disabled){
					background-color: transparent;
					&:hover{
						background-color: transparent !important;
						cursor: default !important;
					}
				}
			}
			&.interacted .item{
				&.active{
					background-color: #f0f0f0;
					// i{
					// 	color: #2185D0;
					// }
				}
				&:not(.active){
					background-color: transparent;
				}
			}
		}

		.co.summary{
			background-color: #f5f5f5;
			width: 100%;
			border-radius: 8px;
			padding: 10px 15px;

			.item{
				margin-bottom: 10px;
				.meta{
					color: gray;
					font-size: 12px;
					line-height: 14px;
					margin-bottom: 2px;
				}
				.text{
					font-size: 18px;
					font-weight: bold;
				}

				&:last-child{
					margin-bottom: 0;
				}
			}
		}

		.fr.fields{
			.small{
				display: flex;
				flex-grow: 100;
				margin-left: 2px;
				width: 33%;

				.fr.input:last-child{
					margin-left: 3px;
				}
			}
		}

		.next-buttons{
			display: flex;
			justify-content: space-between;
			margin-top: 20px;

			&.single{
				justify-content: flex-end;
			}
		}

		// .fr.popup{
		// 	top: unset;
		// 	bottom: calc(100% + 10px);
		// }
	}
	.cart{
		max-width: 500px;
		width: 50%;
		padding: 15px 30px;
		background-color: #f0f0f0;

		.head{
			.fr.header{
				margin-bottom: 15px;
			}
			.total{
				display: none;
			}
		}
		
		.co.cart-item{
			border-bottom-color: #DDD;
			.meta{
				color: gray;
			}
		}

		.totals .amount{
			display: flex;
			.fr.placeholder{
				background-color: black;
			}
			.label{
				flex-grow: 100;
			}
			&.final{
				font-size: 18px;
				margin-top: 10px;
				.value{
					font-weight: bold;
				}
			}
		}
	}
	
	@media screen and (max-width: 1000px){
		.cart{
			max-width: 400px;
		}
	}
	@media screen and (max-width: 810px){
		flex-direction: column-reverse;
		> * {
			height: auto;
			width: 100% !important;
			max-width: 100% !important;
		}
		.cart{
			padding: 0;
			.head{
				cursor: pointer;
				display: flex;
				padding: 10px;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #DDD;
				border-top: 1px solid #DDD;

				.left{
					flex-grow: 100;
					display: flex;
					align-items: center;
					i{
						margin-right: 10px;
					}
				}

				.fr.header{
					margin: 0;
					padding: 0;
					font-size: 24px;
					display: block;
					&.total{
						font-size: 16px;
					}
				}
			}
			.items{
				padding: 0 15px;
				margin-top: 10px;
			}
			.totals{
				padding: 0 15px 15px 15px;
			}
		}
		> .contents{
			padding-top: 5px;
			height: auto;
			padding-bottom: 50px;

			.fr.breadcrumbs{
				margin-bottom: 10px;
				justify-content: center;
			}
		}
	}
	@media screen and (max-width: 480px){
		margin-top: -15px;
		> .contents{
			padding: 10px;
			padding-top: 5px;
		}
	}
}