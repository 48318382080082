.co.footer{
   width: 100%;
   background-color: #FEE80F;
   padding: 20px 50px;
	height: 285px;
   margin-top: 20px;

	@media print{
		display: none;
	}
}

.co.footer .logo{
	margin-bottom: 20px;
}
.co.footer .flex{
	display: flex;
	align-items: center;
	max-width: 1200px;
	width: 100%;
	margin: auto;
}
.co.footer .left{
	flex-grow: 100;
}
.co.footer .sections{
	display: flex;
	max-width: 800px;
	justify-content: space-between;
}
.co.footer .sections .section:first-child{
	padding-left: 0;
}
.co.footer .sections .section{
	padding: 10px 20px;
}
.co.footer .sections .section .item{
	display: block;
	color: black;
	padding: 2px 0;
}
.co.footer .sections .section .header{
	color: black;
	font-weight: 600;
}
.co.footer .flex .chocolover{
	max-width: 300px;
	margin-left: 20px;
}

@media screen and (max-width: 1000px){
	.co.footer{
		height: auto;
	}
	.co.footer .flex{
		display: block;
		text-align: center;
	}
	.co.footer .left{
		text-align: left;
	}
}
@media screen and (max-width: 600px){
	.co.footer{
		padding: 30px 40px;
	}
	.co.footer .left .sections{
		display: block;
	}
	.co.footer .left .sections .section{
		padding-bottom: 20px;
		padding-left: 10px;
	}
	.co.footer .sections .section:first-child{
		padding-left: 10px;
	}
	.co.footer .sections .section .header{
		font-size: 18px;
	}
	.co.footer .sections .section .item{
		padding: 5px 0;
	}
	.co.footer .flex .chocolover{
		margin-top: 20px;
		margin-left: 0;
	}
}