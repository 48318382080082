@import '_colors.scss';

.co.site-page-screen{
	.masthead{
		width: 100%;
		min-height: 300px;
		background-color: #f0f0f0;
		color: black;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		object-fit: cover;
		background-size: cover;
		background-position: center;
		.title{
			margin-top: 15px;
			font-size: 50px;
			font-weight: bold;
			text-align: center;
			text-transform: uppercase;
			font-style: italic;
			line-height: 1.1em;
			max-width: 800px !important;

			text-shadow: 1px 1px 10px #00000045;
		}
		.subtitle{
			margin-bottom: 15px;
			text-align: center;
			max-width: 800px !important;
		}
		.button{
			margin-bottom: 15px;
		}

		&.empty{
			.title, .subtitle{
				color: black;
			}
		}
	}
	.content{
		margin: auto;
		max-width: 800px;
		padding: 20px;
		margin-top: 10px;
		text-align: center;
		.text-content{
			font-size: 16px;
			text-align: left;
		}
		.pdf-text{
			color: gray;
			font-size: 12px;
			margin-top: 15px;
			margin-bottom: 10px !important;
		}

		.assets{
			display: flex;
			flex-wrap: wrap;
			max-width: 100%;
			margin: auto;
			justify-content: center;
			cursor: pointer;

			.asset{
				padding: 10px;
				transition: all 100ms;
				img{
					object-fit: fill;
					width: 200px;
					border-radius: 8px;
					transition: all 500ms;
				}
				.name{
					font-size: 16px;
					font-weight: bold;
				}	
				.description{
					font-size: 14px;
					color: gray;
				}
				&:hover{
					img{
						box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
					}
					transform: translateY(-1px);
				}
			}		
		}
	}

	@media screen and (max-width: 700px){
		.masthead{
			min-height: 250px;
		}
	}
	@media screen and (max-width: 450px){
		.masthead{
			min-height: 200px;
		}
	}
}