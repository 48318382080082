.co.public-order-screen{
	margin-top: 20px;

	.fr.message{
		max-width: 500px;
		margin: auto;
		margin-top: 15px;
	}

	.fr.message.summary{
		background-color: #f0f0f0;

		.label{
			font-weight: bold;
			text-transform: uppercase;
			margin-top: 15px;
			margin-bottom: 5px;

			&:first-child{
				margin-top: 0;
			}
		}

		.gift-message{
			font-style: italic;
		}
	}

	.fr.message.masthead{
		background-color: #f0f0f0;
		text-align: center;
		margin-bottom: 15px;
				
		.label-hash{
			font-weight: bold;
			font-size: 26px;
			margin-top: 20px;
		}

		.sublabel-hash{
			font-weight: bold;
			margin-top: 20px;
			font-size: 18px;
		}

		.icon{
			margin: 20px 0;
		}
	}

	.co.items{
		max-width: 500px;
		margin: auto;
		margin-top: 15px;
		.item{
			display: flex;
			margin-bottom: 15px;
			.co.product-image{
				min-width: 120px;
				.image{
					border: 1px solid #f0f0f0;
				}
			}
			.info{
				padding: 5px 5px 5px 0;
				margin-left: 10px;
				.name{
					color: black;
					font-size: 16px;
					font-weight: bold;
					line-height: 20px;
				}
				.size{
					color: gray;
				}
				.cost{
					margin: 10px 0 2px 0;
					font-size: 16px;
					font-weight: bold;
				}
				.quantity .number{
					font-weight: bold;
				}
			}
		}
	}
	table.totals{
		box-shadow: none;
		max-width: 500px;
		border-radius: 0;
		margin: auto;

		tr{
			text-align: right;
			color: #555;
			td{
				padding: 5px !important;
				padding-top: 0 !important;
				&:last-child{
					min-width: 150px;
				}
			}
			&:last-child{
				color: black;
				font-size: 18px;
				td{
					padding-top: 10px !important;
					font-weight: bold;
				}
			}
		}
	}

	.co.options{
		max-width: 500px;
		margin: auto;
		margin-top: 50px;
		display: flex;
		padding: 15px;
		justify-content: space-between;

		.option{
			text-align: center;
			display: flex;
			align-items: center;
			flex-direction: column;
			i.icon{
				margin: 0;
			}
			.button{
				margin-top: 5px;
			}
			.text{
				font-weight: bold;
				text-align: center;
				margin-top: 5px;
			}
		}
	}

	.co.footer{
		max-width: 500px;
		margin: auto;
		text-align: center;
		.icon{
			margin: 10px;
			cursor: pointer;
			color: black;
		}
		.icon:hover{
			color: rgb(45, 45, 45);
		}
	}

	@media screen and (max-width: 550px){
		.fr.message.summary, .fr.message.masthead{
			margin: 0 5px;
		}
		.fr.message.masthead{
			margin-bottom: 15px;
		}
		table.fr.table.totals{
			margin: 0 10px;
			width: calc(100% - 20px);
		}
		.co.items{
			padding: 0 15px;
		}
		.co.options{
			display: block;
			margin-top: 20px;
			.option:not(:first-child){
				margin-top: 15px;
			}
		}
	}
}
