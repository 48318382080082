@import '_colors.scss';

.co.receipt{
	.logo{
		height: 50px;
		display: block;
		margin: auto;
		margin-bottom: 20px;
	}
   .header{
      padding: 15px;
      text-align: center;
   }
   .table{
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-top: 0;

      .label{
         text-align: center; 
         font-weight: bold;
      }
   }
   .row{
      border-bottom: 1px solid #dbdbdb
   }
   .footer{
      text-align: center;
		padding: 15px;
      display: block;
		color: black;

      .cocoan-link{
         color: black;
         font-weight: bold;
      }
      .socials{
         margin-bottom: 20px;
         .icon{
            text-decoration: none !important;
            color: black !important;
         }
         a{
            text-decoration: none !important;
            color: black !important;
         }
      }
   }
}