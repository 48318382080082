@import "_colors.scss";

.co.body-container{
	padding-top: 60px;
	padding-bottom: 30px;
	min-height: 80vh;
	&.fullscreen{
		padding-bottom: 0;
	}
	@media print{
		padding-top: 0;
		padding-bottom: 0;
	}
}

.co.navbar{
	display: flex;
	justify-content: space-between;
	background-color: $COCOAN_YELLOW;
	padding: 10px;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;

	>.left,>.right{
		width: 100px;
		// background-color: red;
		display: flex;
		align-items: center;
	}
	>.right, >.menu, >.back{
		flex-direction: row-reverse;
		> .item{
			padding-top: 0;
			padding-bottom: 0;
			height: 40px;
			display: flex;
			align-items: center;
			margin: 0 2px;
			position: relative;

			> i{
				font-size: 20px;
				margin: 0 !important;
			}

			.tag{
				position: absolute;
				top: 50%;
				left: 55%;
				background-color: rgb(230, 25, 25);
				color: white;
				width: 20px;
				height: 20px;
				font-size: 12px;
				line-height: 18px;
				// padding-top: 1px;
				text-align: center;
				vertical-align: middle;
				border-radius: 100px;
			}

			&:first-child{
				margin-right: 10px;
			}
		}
	}
	>.logo img, > .logo{
		height: 30px;
	}
	> .left > .item, > .right > .item{
		color: black;
		padding: 5px 15px;
		margin: 0 2px;
		font-weight: bold;
		border-radius: 100px;
		cursor: pointer;
		display: flex;
		align-items: center;

		i{
			margin-right: 10px;
		}

		&:hover{
			// text-decoration: underline;
			// text-underline-offset: 4px;
			// text-decoration-thickness: 2px;
			background-color: darken($COCOAN_YELLOW, 3%);
		}
	}
	> .menu{
		display: none;
		width: 100px;
		color: black;
		.item{
			width: 40px;
		}
	}

	@media screen and (max-width: 700px){
		.item{
			margin: 0;
			padding: 5px 10px;
		}
	}
	@media screen and (max-width: 1000px){
		> .left{
			display: none;
		}
		> .menu{
			display: block;
		}
	}
	@media screen and (max-width: 480px){
		justify-content: flex-start;
		> .menu{
			width: auto;
		}
		> .right{
			flex-grow: 100;
		}
		>.logo img, > .logo{
			height: 25px;
		}
	}
	@media print{
		display: none;
	}
}

.co.cart{
	position: fixed;
	right: 0;
	top: 0;
	width: 80%;
	max-width: 450px;
	height: 100%;
	z-index: 1000;
	padding: 10px;
	// background-color: white;
	background-color: $COCOAN_YELLOW;
	display: flex !important;
	flex-direction: column;

	.head{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 10px;
		border-bottom: 2px solid darken($COCOAN_YELLOW, 4%);
		margin-bottom: 10px;
		padding: 5px 10px 10px 10px;
		
		.text{
			font-size: 30px;
			font-weight: bold;
		}
		i.icon{
			font-size: 30px;
			margin: 0;
			cursor: pointer;
			&:hover{
				color: lighten(black, 30%)
			}
		}
	}

	.items{
		flex-grow: 1;
		overflow-y: scroll;
		.co.cart-item:first-child{
			margin-top: 5px;
		}
		.progress{
			margin-bottom: 20px;

			.header{
				font-weight: bold;
				text-align: center;
				.label{
					display: inline-block;
					color: white;
					background-color: black;
					padding: 2px 8px;
					margin-top: 0;
					border-radius: 8px;
				}
			}
		}
	}

	.totals{
		padding: 15px 10px 20px 10px;
		font-size: 16px;
		table tbody tr.total{
			font-weight: bold;
		}
	}

	.divider{
		height: 1px;
		border-bottom: 2px solid darken($COCOAN_YELLOW, 4%);
		padding-top: 10px;
		margin-bottom: 20px;
	}

	.fr.table{
		border: 0;
		box-shadow: none;
		background-color: transparent;
		tbody tr td{
			padding: 8px 0 !important;
			&.collapsing{
				text-align: right;
			}
		}
	}

	.item{
		display: flex;
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid darken($COCOAN_YELLOW, 3%);

		.product-image{
			min-width: 100px;
			width: 100px;
			margin-right: 15px;
		}
		.info{
			.name{
				font-size: 16px;
				// font-weight: bold;
			}
			.price{
				margin-top: 10px;
				display: flex;
				font-size: 16px;
				font-weight: bold;
	
				.discounted{
					text-decoration: line-through;
					color: gray;
					font-weight: normal;
				}
				.final{
					margin-right: 10px;
					font-weight: bold;
					color: brown;
				}
			}
			.size{
				color: lighten(black, 40%);
			}
			.quantity{
				display: inline-flex;
				align-items: center;
				position: relative;
				margin-top: 5px;
				select{
					display: block;
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
				}
				i.icon{
					margin-left: 5px;
					font-size: 12px;
					line-height: 14px;
				}
			}
		}

		&:last-child{
			border-bottom: 0;
		}

		@media screen and (max-width: 500px){
			.product-image{
				min-width: 80px;
				width: 80px;
			}
		}
	}
}

.co.sidemenu{
	position: fixed;
	left: 0;
	top: 60px;
	width: 80%;
	max-width: 320px;
	height: calc(100% - 60px);
	z-index: 1000;
	padding: 10px;
	// background-color: $COCOAN_YELLOW;
	background-color: white;
	// border-top: 2px solid darken($COCOAN_YELLOW, 4%);
	border-top: 2px solid darken(white, 10%);

	.item{
		min-width: 280px;
		font-size: 20px;
		padding: 15px 10px;
		cursor: pointer;
		display: block;
		color: black;
		// border-bottom: 1px solid darken($COCOAN_YELLOW, 4%);
		border-bottom: 1px solid darken(white, 10%);
		font-weight: bold;

		&.dropdown{
			padding: 0;
			.content{
				display: flex;
				align-items: center;
				.text{
					flex-grow: 100;
					padding: 15px;
				}
				i.dropdown.icon{
					margin-right: 10px;
					padding-left: 10px;
					margin-right: 15px;
				}
			}
			.menu{
				.item{
					display: block;
					background-color: #1e1e24;
					padding: 15px;
					font-size: 18px;
					color: white;
					margin-bottom: 0;
				}
			}
		}

		&:last-child{
			border-bottom: 0;
		}
	}
}

.co.dimmer{
	position: fixed;
	width: 100%;
	height: 100%;
	// background-color: darken(rgb(255, 234, 3, 0.6), 30%);
	background-color: rgb(0, 0, 0, 0.6);
	z-index: 999;
	
	&.nav{
		top: 60px;
		height: calc(100% - 60px);
	}
}

.co.searchbar{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	max-height: 200px;
	background-color: $COCOAN_YELLOW;
	z-index: 1000;
	padding: 20px;

	.contents{
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;

		.fr.header{
			margin-top: 0;
			margin-bottom: 15px;
		}
		input{
			background-color: darken($COCOAN_YELLOW, 3%);
			border: 0;
			text-align: center;
			font-size: 24px;
			padding: 15px;
			color: black;

			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
	> .close{
		position: absolute;
		top: 15px;
		right: 15px;
		font-size: 30px;
		cursor: pointer;
		&:hover{
			color: lighten(black, 30%)
		}
	}
	.comment{
		color: darken($COCOAN_YELLOW, 20%);
		text-align: center;
		&.hidden{
			color: transparent;
		}
	}

	@media screen and (max-width: 500px){
		// max-height: 180px;
		.contents{
			.fr.header{
				font-size: 20px;
			}
			input{
				font-size: 20px;
			}
		}
	}
}

.fr.yellow.button{
	background-color: $COCOAN_YELLOW;
	color: black;
	&:hover{
		background-color: lighten($COCOAN_YELLOW, 15%);
	}
}
.fr.yellow.label{
	background-color: $COCOAN_YELLOW;
	color: black;
}

.co.product{
	display: block;
	margin: 5px;
	width: 300px;
	position: relative;

	.image{
		width: 100%;
		padding-top: calc((5 / 4 * 100%) - 5px);
		position: relative;
		border-radius: 13px;
		background-color: darken(#FFFFFF, 5%);
		border: 1px solid darken(#FFFFFF, 5%);

		img{
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 12px;
			transition: all 100ms;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}
		// height: 275px;
		// width: 220px;
		.message{
			position: absolute;
			top: 10px;
			left: 10px;
			color: #151717;
			background-color: $COCOAN_YELLOW;
			padding: 5px 15px;
			max-width: 75%;
			border-radius: 100px;
			font-weight: bold;
		}
	}
	.info{
		width: 100%;
		// background-color: blue;
		padding: 10px;
		color: black;
		.name{
			font-weight: bold;
			// background-color: red;
			font-size: 16px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;
		}
		.price{
			margin-top: 5px;
			display: flex;
			font-size: 16px;
			font-weight: bold;

			.discounted{
				text-decoration: line-through;
				color: gray;
				font-weight: normal;
			}
			.final{
				margin-right: 10px;
				font-weight: bold;
				color: brown;
			}
			.size{
				font-size: 14px;
				margin-left: 5px;
				color: gray;
				font-weight: normal;
				flex-grow: 100;
				text-align: right;
			}
		}
	}

	&.link{
		cursor: pointer;
	}

	&.placeholder{
		.image{
			background-color: $PLACEHOLDER_COLOR;
			border-color: $PLACEHOLDER_COLOR;
		}
		.info{
			.name{
				height: 22px;
				background-color: $PLACEHOLDER_COLOR;
				border-radius: 100px;
				width: 52%;
			}
			.price{
				height: 18px;
				background-color: $PLACEHOLDER_COLOR;
				border-radius: 100px;
				width: 62px;
			}
		}

		&.active{
			.image{
				background-color: $PLACEHOLDER_ACTIVE_COLOR;
				border-color: $PLACEHOLDER_ACTIVE_COLOR;
			}
			.info .name, .info .price{
				background-color: $PLACEHOLDER_ACTIVE_COLOR;
			}
		}
	}
}

.fr.carrousel{
	.header{
		display: flex;
		align-items: flex-end;
		padding: 0 25px;
		margin-bottom: 10px;
		
		.text{
			font-size: 30px;
			line-height: 30px;
			font-weight: bold;
			flex-grow: 100;

			&.placeholder{
				color: transparent;
				flex-grow: 0;
				width: 150px;
				font-size: 0;
				border-radius: 100px;
				background-color: $PLACEHOLDER_COLOR;
			}
		}

		.navigation i.icon{
			font-size: 16px;
			padding: 7px;
			width: 30px;
			height: 30px;
			background-color: black;
			border-radius: 100px;
			color: white;
			cursor: pointer;

			&.disabled{
				color: gray;
				background-color: transparent;
			}
			&:hover:not(.disabled){
				background-color: lighten(black, 20%);
			}
			&:first-child{
				margin-right: 10px;
			}
		}

		.action{
			padding-left: 15px;
		}
	}
	.scroll{
		display: flex;
		align-items: flex-start;
		overflow-x: scroll;
		position: relative;

		scroll-snap-type: x mandatory;
		scroll-padding: 0 20px;
		padding: 0 20px;
		padding-bottom: 20px;

		scrollbar-width: thin;

		> *{
			scroll-snap-align: start;
		}
	}
}

.fr.breadcrumbs{
	display: flex;
	align-items: center;

	.item{
		cursor: pointer;
		color: gray;
		&.active{
			color: black;
		}
		&:not(.active){
			&:hover{
				color: darken(gray, 20%);
			}
		}
	}
	i.icon{
		font-size: 12px;
		margin: 0 5px;
		color: gray;
	}
}

.co.product-image{
	display: block;
	width: 300px;
	position: relative;

	.image{
		width: 100%;
		padding-top: calc((5 / 4 * 100%) - 5px);
		position: relative;
		border-radius: 13px;
		background-color: darken(#FFFFFF, 5%);
		// border: 1px solid darken(#FFFFFF, 5%);

		img{
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 12px;
			transition: all 100ms;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}
		// height: 275px;
		// width: 220px;
		.message{
			position: absolute;
			top: 10px;
			left: 10px;
			color: #151717;
			background-color: $COCOAN_YELLOW;
			padding: 5px 15px;
			max-width: 75%;
			border-radius: 100px;
			font-weight: bold;
		}
	}
}

.fr.header .actions{
	font-size: 16px;
}

.fr.input{
	margin-bottom: 8px;
}

.fr.fields.two{
	> .fr.input{
		width: calc(50% - 2px) !important;
		&:first-child{
			margin-right: 2px !important;
		}
		&:last-child{
			margin-left: 2px !important;
		}
	}
	@media screen and (max-width: 500px){
		display: block;

		> .fr.input, > .fr.field{
			margin-left: 0 !important;
			margin-right: 0 !important;
			width: 100% !important;
			&:first-child{
				margin-bottom: 10px;
			}
		}
	}
}


.fr.loading.input{
	input{
		padding-left: 38px !important;
	}
	&::before,&::after{
		z-index: 2;
		left: 23px;
		top: calc(100% - 22px);
	}
}

.co.progress{
	width: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	height: 8px;
	border-radius: 16px;
	overflow: hidden;

	.bar{
		background-color: black;
		border-radius: 16px;
		height: 8px;
		width: 0px;
		transition: all 500ms;
	}
}

.co.quantity{
	display: flex;
	justify-content: center;

	> *{
		padding: 5px;
		margin: 0 5px;
	}
	> .button{
		padding: 5px 15px;
		cursor: pointer;

		&:hover{
			background-color: rgba(0,0,0,0.05);
			border-radius: 4px;
		}
	}
	.text{
		background-color: rgba(0,0,0,0.03);
		text-align: center;
		min-width: 80px;
		border-radius: 4px;
		font-weight: bold;
		font-size: 16px
	}
	
	i.icon{
		margin: 0;
	}
}