@import "_colors.scss";

@font-face {
	font-family: Salacious;
	src: url(https://cdn.emperar.mx/cocoan/static/header_font.otf);
}

.co.landing{
	.masthead{
		width: 100%;
		height: 500px;
		background-color: #A05424;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		object-fit: cover;
		background-size: cover;
		background-position: center;

		.text{
			font-weight: bold;
			font-size: 50px;
			line-height: 60px;
			padding: 0 20px;
			color: $COCOAN_YELLOW;
			margin-bottom: 20px;

			img{
				max-width: 700px;
			}
		}
		.button{
			margin-top: 10px;
		}
	}

	.co.people{
		display: flex;
		max-width: 1000px;
		margin: auto;
		margin-top: 30px;
		justify-content: center;
	}
	.fr.carrousel{
		margin: auto;
		margin-top: 40px;
		max-width: 1200px;
		.header{
			font-family: Salacious, Roboto;
		}

		.co.product{
			min-width: calc(25% - 10px);
			margin-left: 0;
			margin-right: 10px;
		}
		
		@media screen and (min-width: 1201px){
			.header{
				padding-left: 0;
			}
			.scroll{
				padding: 0;
				scroll-padding: 0 0;
			}
		}
		@media screen and (max-width: 1200px){
			.co.product{
				min-width: 30%;
			}
		}
		@media screen and (max-width: 800px){
			.co.product{
				min-width: 40%;
			}
		}
		@media screen and (max-width: 450px){
			.co.product{
				min-width: 80%;
			}
		}
	}

	.fr.main.header{
		font-family: Salacious, Roboto;
		.sub.header{
			font-family: Roboto;
		}
	}

	.co.people{
		flex-wrap: wrap;
	}

	.co.information{
		display: flex;
		width: 100%;
		background-color: #FDE778;
		height: 300px;
		margin-top: 20px;

		img.store,img.drawing{
			display: block;
			height: 100%;
			max-width: 350px;
			width: 100%;
			box-shadow: none;
			border: 0;
			object-fit: cover;
			object-position: top;
		}

		img.drawing{
			object-fit: cover;
			object-position: left;
		}

		.info{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex-grow: 100;
			padding: 15px;
			position: relative;
			> *{
				width: 100%;
				max-width: 500px;
			}
			.title{
				font-family: Salacious, Roboto;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
				margin-top: 20px;
				margin-bottom: 5px;
				&:first-child{
					margin-top: 0;
				}
			}
			.content{
				line-height: 24px;
			}

		}

		@media screen and (min-width: 1201px){
			img.store{
				max-width: 500px;
				padding-right: 30px;
			}
		}
		@media screen and (max-width: 1200px) {
			height: 280px;
			img.store{
				max-width: 300px;
			}
			img.drawing{
				max-width: 250px;
			}
		}
		@media screen and (max-width: 950px){
			img.store{
				max-width: 250px;
			}
			img.drawing{
				max-width: 180px;
			}
		}
		@media screen and (max-width: 705px){
			img.store{
				max-width: 300px;
			}
			img.drawing{
				display: none;
			}
		}
		@media screen and (max-width: 570px){
			img.store{
				max-width: 200px;
			}
		}
		@media screen and (max-width: 480px){
			display: block;
			height: auto;
			img.store,img.drawing{
				height: 200px;
				width: 100%;
				max-width: 100%;
			}
			.info{
				text-align: center;
				padding-bottom: 20px;
			}
			img.drawing{
				display: block;
				height: 250px;
				object-fit: contain;
				object-position: center;
				padding-bottom: 20px;
			}
		}
	}

	@media screen and (max-width: 700px){
		.masthead .text img{
			width: 100%;
		}
	}
	@media screen and (max-width: 600px) {
		.masthead{
			height: 400px;
			.text{
				padding: 10px;
			}
		}
	}
	@media screen and (max-width: 500px) {
		.masthead{
			height: 300px;
		}
	}
}

.co.person{
	width: 220px;
	margin-right: 30px;
	margin-bottom: 25px;
	position: relative;
	img, .image{
		height: 275px;
		width: 220px;
		border: 2px solid darken(#FFFFFF, 10%);
		border-radius: 12px;
	}
	.info{
		background-color: #151717;
		position: absolute;
		right: -15px;
		bottom: -10px;
		width: 200px;
		color: white;
		padding: 10px;
		border-radius: 10px;
		min-height: 64px;
		> *{
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;
			width: 100%;
		}
		.name{
			font-size: 16px;
			line-height: 24px;
		}
		.meta{
			color: #CCC;
		}
	}

	&.placeholder{
		.image{
			background-color: $PLACEHOLDER_COLOR;
			border: 0px;
		}
		.info{
			background-color: darken($PLACEHOLDER_COLOR, 8%);
		}
	}
}