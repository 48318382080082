@import '_colors.scss';

.co.visit-us-screen{
	margin-top: 20px;
	
	.main-header{
		font-size: 35px;
	}

	.main-content{
		max-width: 1100px;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 15px;
	}

	.image-container{
		position: relative;
		width: calc(50% - 5px);
		.row{
			text-align: center;
			img{
				&:first-child{
					margin-right: 5px;
				}
				width: calc(50% - 5px);
			}
		}
	}

	.info-content{
		padding-left: 50px;
		width: calc(50% - 10px);

		iframe{
			width: 100%;
		}

		.fr.header{
			text-align: left;
			font-size: 24px;
			margin-bottom: 15px;
			margin-top: 25px;
		}
		.address{
			margin-top: 10px;
			display: flex;
		}
		.schedule .closed{
			font-weight: bold;
		}

		@media screen and (max-width: 800px){
			padding-left: 20px;
		}
	}

	@media screen and (max-width: 600px){
		.image-container{
			width: 100%;
		}
		.info-content{
			margin-top: 15px;
			width: 100%;
			padding-left: 0px;
		}
	}
}