@import '_colors.scss';

.co.shop{
	.announcement{
		background-color: #EEE;
		color: black;
		text-align: center;
		padding: 10px;
		font-weight: bold;

		.fr.placeholder{
			background-color: darken($PLACEHOLDER_COLOR, 5%) !important;
		}
	}
	> .header, .ui.sticky > .header{
		width: 100%;
		background-color: white;

		.contents{
			width: 100%;
			height: 52px;
			max-width: 1200px;
			margin: auto;
			display: flex;
			align-items: center;
			padding: 10px 15px;

			.name{
				flex-grow: 100;
				font-weight: bold;
				font-size: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.products{
					font-weight: normal;
					font-size: 12px;
					color: gray;
					line-height: 12px;
					margin-right: 20px;
				}
			}
			.fr.button{
				min-width: 100px;
				display: none;
			}

			@media screen and (max-width: 550px){
				.name{
					display: block;
					.products{
						margin: 0;
						padding: 5px 0;
					}
				}
			}
			@media screen and (max-width: 700px){
				.fr.button{
					display: block;
				}
			}
		}
	}
	.ui.sticky.fixed > .header{
		transition: any 500ms;
		border-bottom: 1px solid #CCC;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	}

	.contents{
		position: relative;
		.sidebar{
			position: absolute;
			width: 250px;
		}
		> .title{
			padding: 0 20px;
			> .header{
				font-size: 40px;
				font-weight: bold;
				text-align: center;
				text-transform: uppercase;
				font-style: italic;
				line-height: 1.1em;
			}
			.description{
				max-width: 800px;
				margin: auto;
				text-align: center;
				color: #5e5e5e;
				font-family: inherit;
				white-space: pre-wrap;
			}
		}
		.products{
			max-width: 1200px;
			margin: auto;
			padding: 10px;
			top: 0;
			display: flex;
			flex-wrap: wrap;
			.co.product{
				margin: 3px;
				min-width: calc(33% - 4px);
				width: calc(33% - 4px);
			}
		}
		.empty{
			font-size: 20px;
		}
		.categories{
			padding: 10px;
			top: 0;
			display: flex;
			flex-wrap: wrap;
			max-width: 1000px;
			margin: auto;
			.co.category{
				min-width: calc(33% - 4px);
				width: calc(33% - 4px);
			}
		}
		@media screen and (max-width: 850px){
			.products{
				.co.product{
					min-width: calc(50% - 10px);
					width: calc(50% - 10px);
				}
			}
		}
		@media screen and (max-width: 700px){
			.sidebar{
				display: none;
			}
			.categories .co.category{
				min-width: calc(33% - 5px);
				width: calc(33% - 5px);
			}
			.products{
				margin-left: 0;
				.co.product{
					min-width: calc(33% - 9px);
					width: calc(33% - 9px);
				}
			}

			> .title{
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}
		@media screen and (max-width: 600px){
			.products .co.product{
				min-width: calc(50% - 10px);
				width: calc(50% - 10px);
				.price .size{
					font-size: 12px;
				}
			}

			.categories .co.category{
				min-width: calc(50% - 6px);
				width: calc(50% - 10px);
			}

			> .title .header{
				font-size: 32px;
			}
		}
	}

	&.sidebar{
		.contents .products{
			margin-left: 250px;
			max-width: auto;
		}
	}


	.co.sidebar{
		padding: 15px;
		.filter{
			margin-bottom: 30px;

			.title{
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: 10px;
				margin-left: 2px;
			}
			.item{
				background-color: #f5f5f5;
				margin-bottom: 5px;
				border-radius: 100px;
				padding: 5px 15px;
				cursor: pointer;

				&:hover{
					background-color: #F0F0F0;
				}

				&.active{
					background-color: $COCOAN_YELLOW;
					color: black;
					font-style: italic;
					font-weight: bold;

					display: flex;
					justify-content: space-between;
					align-items: center;

					i.icon{
						display: none;
					}
					
					&:hover{
						i.icon{
							display: block;
						}
						background-color: lighten($COCOAN_YELLOW, 15%);
					}
				}
			}

			&:last-child{
				margin-bottom: 0;
			}

			&.placeholder{
				.title{
					height: 22px;
					background-color: $PLACEHOLDER_ACTIVE_COLOR;
					border-radius: 100px;
					width: 49%;
					margin-left: 2px;
				}
				.item{
					height: 30px;
					background-color: $PLACEHOLDER_COLOR;
					cursor: default;
				}
			}
		}
	}
}