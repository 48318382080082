@import '_colors.scss';

.co.product-screen{
	max-width: 1100px;
	margin: auto;
	display: flex;
	align-items: flex-start;
	padding: 15px;
	position: relative;

	.images{
		flex-grow: 100;
		margin-right: 20px;
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.item{
			margin: 5px;
			position: relative;
			width: calc(50% - 10px);
			cursor: pointer;
			.image{
				padding-top: calc((5 / 4 * 100%) - 5px);
				position: relative;
				background-color: $PLACEHOLDER_COLOR;
				border-radius: 20px;

				img{
					width: 100%;
					height: 100%;
					display: block;
					border-radius: 12px;
					transition: all 100ms;
					object-fit: cover;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
				}
			}
		}
	}
	.navigation{
		position: absolute;
		top: calc(((100vw * 1.25) - 40px)/2);
		display: none;
		&.right{
			right: 15px;
		}
		&.left{
			left: 15px;
		}

		i.icon{
			background-color: white;
			padding: 8px;
			width: 30px;
			height: 30px;
			border-radius: 30px;
			cursor: pointer;
			box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
		}
	}

	&.single{
		.images{
			justify-content: center;
			align-items: center;
			.item{
				width: 80%;
			}
		}
		.navigation{
			display: none !important;
		}
	}

	&.double{
		.images{
			justify-content: center;
			.item{
				width: 80%;
				margin: 0;
				margin-bottom: 10px;
			}
		} 
	}

	.information{
		width: 400px;

		.fr.breadcrumbs{
			margin-bottom: 10px;
		}

		.name{
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			margin-bottom: 15px;
		}

		.price{
			font-size: 20px;
			margin-top: 17px;
			font-weight: bold;
			align-items: flex-end;
			display: flex;

			.discounted{
				text-decoration: line-through;
				color: gray;
				font-weight: normal;
				font-size: 14px;
				line-height: 18px;
			}
			.final{
				margin-right: 10px;
				font-weight: bold;
				color: brown;
			}
		}

		.co.presentation,.section{
			margin-top: 15px;
			.title{
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 5px;
				margin-left: 2px;
			}
			.items{
				display: flex;
				flex-wrap: wrap;
				.item{
					font-size: 14px;
					padding: 10px 20px;
					background-color: $PLACEHOLDER_COLOR;
					border-radius: 8px;
					border: 2px solid $PLACEHOLDER_COLOR;
					cursor: pointer;
					margin-right: 5px;
					margin-bottom: 5px;
					text-align: center;
					width: calc(33% - 5px);

					&:last-child{
						margin-right: 0;
					}
					&.active{
						background-color: $COCOAN_YELLOW;
						border: 2px solid darken($COCOAN_YELLOW, 2%);
						font-weight: bold;
					}
				}
			}
			.fr.message{
				p{
					margin-bottom: 0;
				}
				ul, ol{
					margin-bottom: 10px;
				}
			}
		}
		.fr.add.button{
			margin-top: 15px;
			margin-bottom: 20px;
		}
	}
	@media screen and (max-width: 850px){
		padding: 10px;
		.information{
			width: 300px;
			.co.presentation .items .item{
				width: calc(50% - 5px);
			}
		}
	}
	@media screen and (max-width: 750px){
		display: block;
		.images{
			flex-direction: row;
			flex-wrap: nowrap;
			margin-right: 0;

			overflow-x: scroll;
			scroll-snap-type: x mandatory;
			scroll-padding: 0 0px;
			// padding: 0 20px;
			padding-bottom: 5px;
			margin-bottom: 10px;
			scroll-behavior: smooth;

			scrollbar-width: thin;
			
			.item{
				scroll-snap-align: start;
				min-width: 100%;
				margin: 0;
				// background-color: red;
			}
		}
		.information{
			width: 100%;
			.co.presentation .items .item{
				width: calc(33% - 5px);
			}
		}
		.navigation{
			display: block;
		}
	}
	@media screen and (max-width: 500px){
		.information .co.presentation .items .item{
			width: calc(50% - 5px);
		}
	}
}